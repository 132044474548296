import React from "react"
import { Link } from "gatsby"

import parser from "react-html-parser"
const Herohome = ({ herodata }) => {
  let Homedata = herodata.wordpressPage

  return (
    <>
      <section className="hero" id="hero">
        <div className="container-fluid">
          <div className="row hero-row">
            <div className="col-lg-1"></div>
            <div className="col-lg-3 col-md-12  d-flex justify-content-center flex-column hero-cont ">
              <div className="title">
                <h1 className="heading">{Homedata.acf.title}</h1>
              </div>
              <div className="title-desc pt-lg-3 pb-lg-3">
                <h3 className="description">
                  {parser(Homedata.acf.description)}
                </h3>
              </div>

              <div className="btn-demo">
                <Link to="/contact#schedule" className="hero-btn">
                  <button className="btn-schedule-demo info-firstbtn">
                    Schedule a Demo
                  </button>
                </Link>
              </div>
            </div>
            <div className="col-lg-7 col-md-12  d-flex justify-content-center flex-column hero-img">
              <picture>
                <source
                  media="(max-width:768px)"
                  srcset={
                    Homedata.acf.tablet_image.localFile.childImageSharp.fluid
                      .originalImg
                  }
                />

                <source
                  media="(max-width:480px)"
                  srcset={
                    Homedata.acf.mobile_image.localFile.childImageSharp.fluid
                      .originalImg
                  }
                />

                <img
                  src={
                    Homedata.acf.image.localFile.childImageSharp.fluid
                      .originalImg
                  }
                  alt="hero-landing"
                  className="hero-landingsrc"
                />
              </picture>
            </div>

            <div className="col-lg-1"></div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Herohome

export const Heroquery = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        description
      }
    }
  }
`
