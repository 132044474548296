import React from "react"

import "bootstrap/dist/css/bootstrap.min.css"
import "jquery/dist/jquery.min.js"

import "popper.js"

import "react-bootstrap-country-select/dist/react-bootstrap-country-select.css"
import "../css/Media-1920-2560.css"
// import "../css/Media-1440-1919.css"
import "../css/Media-1024-1439.css"
import "../css/Media-768-1023.css"
import "../css/Media-768-1023-Landscape.css"
// import "../css/Media-481-767.css"
import "../css/Media-320-480.css"
import "../css/index.css"
import SEO from "../components/Seo"

import Layout from "../components/layout"
import Logoimg from "../components/Clients"
import Herohome from "../components/Hero-home"
import Homedesc from "../components/Home"
import Homepagedemo from "../components/Homepage-demo"
import { graphql } from "gatsby"
import { Component } from "react"

const Home = ({ data }) => {
  let yoast = data.wordpressPage.yoast_head

  return (
    <>
      <Layout
        logo={data.allWordpressAcfSucceedlearnLogo.edges}
        favicon={data.allWordpressAcfSucceedlearnApple.edges}
      >
        <SEO title={{ yoast }} />
        <Herohome herodata={data} />

        <section className="clients" id="clients">
          <Logoimg
            clientsrow={data.allWordpressAcfClientsLogos}
            title={data.wordpressPage.acf.clients_title}
          />
          <hr className="client-below" />
        </section>
        <Homepagedemo data={data.wordpressPage.acf} />
        <Homedesc herodata={data.wordpressPage} />
      </Layout>
    </>
  )
}

export default Home

export const query = graphql`
  {
    allWordpressAcfSucceedlearnLogo {
      edges {
        node {
          acf {
            logo_image_ {
              localFile {
                childImageSharp {
                  fluid {
                    originalImg
                  }
                }
              }
            }
          }
        }
      }
    }
    allWordpressAcfSucceedlearnApple {
      edges {
        node {
          acf {
            logo_image_ {
              localFile {
                childImageSharp {
                  fluid {
                    originalImg
                  }
                }
              }
            }
          }
        }
      }
    }

    wordpressPage(title: { eq: "SucceedLearn" }) {
      title
      acf {
        clients_title
        image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        title
        description
        infosec_title_homepage
        infosec_subtitle_description_homepage
        infosec_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        tablet_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        mobile_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        why_succeed1_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        why_succeed1_title
        why_succeed1_description

        why_succeed2_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        why_succeed2_title
        why_succeed2_description

        why_succeed3_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        why_succeed3_title
        why_succeed3_description

        infosec_title_homepage
        infosec_subtitle_description_homepage
        infosec_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        infosec_list_one_title
        infosec_list_one_description
        infosec_list_second_title
        infosec_list_second_description
        infosec_list_third_title
        infosec_list_thrid_description
        infosec_list_second_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        infosec_list_one_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        infosec_list_third_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        Harassment_title_homepage
        Harassment_subtitle_description_homepage
        Harassment_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        Harassment_list_one_title
        Harassment_list_one_description
        Harassment_list_one_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        Harassment_list_second_title
        Harassment_list_second_description
        Harassment_list_second_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        Harassment_list_third_title
        Harassment_list_thrid_description
        Harassment_list_third_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        Compliance_title_homepage
        Compliance_subtitle_description_homepage
        Compliance_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        Compliance_list_one_title
        Compliance_list_one_description
        Compliance_list_one_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        Compliance_list_second_title
        Compliance_list_second_description
        Compliance_list_second_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        Compliance_list_third_title
        Compliance_list_thrid_description
        Compliance_list_third_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        Learning_title_homepage
        Learning_subtitle_description_homepage
        Learning_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        Learning_list_one_title
        Learning_list_one_description
        Learning_list_one_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        Learning_list_second_title
        Learning_list_second_description
        Learning_list_second_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        Learning_list_third_title
        Learning_list_thrid_description
        Learning_list_third_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
      }
      yoast_head
    }

    allWordpressAcfClientsLogos {
      edges {
        node {
          acf {
            client_logo_images {
              source_url
            }
          }
        }
      }
    }
  }
`
