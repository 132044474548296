import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"
import Demo from "../components/Demo"
import Quote from "../components/Testimonials"
import { FaAngleDoubleRight } from "react-icons/fa"
import parser from "react-html-parser"
import { Helmet } from "react-helmet"
import WhySucceed from "../components/Whysucceed"

const Homedesc = herodata => {
  let Homedata = herodata.herodata

  return (
    <>
      {/* <section className="why-succeed" id="why-succeed">
        <WhySucceed />
      </section> */}

      <section className="info-sec details" id="info-sec">
        <div className="container-fluid">
          <div className="row info">
            <div className="col-lg-2"></div>
            <div className="col-lg-8 col-md-12">
              <div className="title-infosec">
                {parser(Homedata.acf.infosec_title_homepage)}
              </div>
              <div className="title-desc pr-md-3 pl-md-3 ">
                {parser(Homedata.acf.infosec_subtitle_description_homepage)}
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>

          <div className="row ">
            <div className="col-lg-2 desktop-col"></div>
            <div className="col-lg-4 col-md-12  d-none d-lg-block justify-content-center flex-column info-img infosec-cont">
              <img
                src={
                  Homedata.acf.infosec_image.localFile.childImageSharp.fluid
                    .originalImg
                }
                alt="hero-landing"
                className="infosec-landingsrc"
              />
            </div>

            <div className="col-lg-4 col-md-12   d-flex justify-content-center flex-column infosec-cont pt-4">
              <div className="row  full-icon-desc">
                <div className="col-md-12 col-lg-2 img-top">
                  <img
                    src={
                      Homedata.acf.infosec_list_one_image.localFile
                        .childImageSharp.fluid.originalImg
                    }
                    alt="info-icon1"
                  />
                </div>
                <div className="col-md-12 col-lg-10 icon-desc">
                  {parser(Homedata.acf.infosec_list_one_title)}
                  {parser(Homedata.acf.infosec_list_one_description)}
                </div>

                <hr className="icon-border" />
              </div>

              <div className="row  full-icon-desc">
                <div className="col-md-12 col-lg-2 img-top">
                  <img
                    src={
                      Homedata.acf.infosec_list_second_image.localFile
                        .childImageSharp.fluid.originalImg
                    }
                    alt="info-icon2"
                  />
                </div>
                <div className="col-lg-9 col-md-12 icon-desc">
                  {parser(Homedata.acf.infosec_list_second_title)}
                  {parser(Homedata.acf.infosec_list_second_description)}
                </div>
                <div className="col-lg-1 col-md-12"></div>
                <hr className="icon-border" />
              </div>

              <div className="row  full-icon-desc">
                <div className="col-md-12 col-lg-2 img-top">
                  <img
                    src={
                      Homedata.acf.infosec_list_third_image.localFile
                        .childImageSharp.fluid.originalImg
                    }
                    alt="info-icon3"
                  />
                </div>
                <div className="col-lg-9 col-md-12 icon-desc">
                  {parser(Homedata.acf.infosec_list_third_title)}
                  {parser(Homedata.acf.infosec_list_thrid_description)}
                </div>
                <div className="col-lg-1 col-md-12"></div>
              </div>

              <div className="row full-icon-desc">
                <div className="col-lg-2 col-md-3"></div>
                <div className="col-lg-10 col-md-9 infosec  ">
                  <Link
                    to="/Infosec-data-protection"
                    style={{
                      "padding-top": "15px",
                    }}
                  >
                    <button
                      type="button"
                      class="btn btn-primary btn-sm know-more"
                      id="info-know-more"
                    >
                      Know More <FaAngleDoubleRight />
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-2 deskop-2"></div>
          </div>
        </div>
      </section>

      <section className="harassment details" id="harassment">
        <div className="container-fluid">
          <div className="row info">
            <div className="col-lg-1"></div>
            <div className="col-lg-10 col-md-12">
              <div className="title-harassment">
                {parser(Homedata.acf.Harassment_title_homepage)}
              </div>
              <div className="title-desc">
                {parser(Homedata.acf.Harassment_subtitle_description_homepage)}
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>

          <div className="row ">
            <div className="col-lg-2 desktop-col"></div>

            <div className="col-lg-4  col-md-12   harassment-cont infosec-cont pt-4">
              <div className="row  full-icon-desc">
                <div className="col-lg-3 col-md-12 img-top ">
                  <img
                    src={
                      Homedata.acf.Harassment_list_one_image.localFile
                        .childImageSharp.fluid.originalImg
                    }
                    alt="harass-icon1"
                  />
                </div>
                <div className="col-lg-9 col-md-12 icon-desc">
                  {parser(Homedata.acf.Harassment_list_one_title)}
                  {parser(Homedata.acf.Harassment_list_one_description)}
                </div>
                <hr className="left-icon-border" />
              </div>

              <div className="row  full-icon-desc">
                <div className="col-lg-3 col-md-12 img-top">
                  <img
                    src={
                      Homedata.acf.Harassment_list_second_image.localFile
                        .childImageSharp.fluid.originalImg
                    }
                    alt="harass-icon2"
                  />
                </div>
                <div className="col-lg-9 col-md-12 icon-desc">
                  {parser(Homedata.acf.Harassment_list_second_title)}
                  {parser(Homedata.acf.Harassment_list_second_description)}
                </div>
                <hr className="left-icon-border" />
              </div>

              <div className="row  full-icon-desc">
                <div className="col-lg-3 col-md-12 img-top">
                  <img
                    src={
                      Homedata.acf.Harassment_list_third_image.localFile
                        .childImageSharp.fluid.originalImg
                    }
                    alt="harass-icon3"
                  />
                </div>
                <div className="col-lg-9 col-md-12 icon-desc">
                  {parser(Homedata.acf.Harassment_list_third_title)}
                  {parser(Homedata.acf.Harassment_list_thrid_description)}
                </div>
              </div>

              <div className="row full-icon-desc">
                <div className="col-md-3"></div>
                <div className="col-md-9 infosec">
                  <Link
                    to="/Harassment-discrimination-courses"
                    style={{
                      "padding-top": "15px",
                    }}
                  >
                    <button
                      type="button"
                      class="btn btn-primary btn-sm know-more"
                      id="harass-know-more"
                    >
                      Know More <FaAngleDoubleRight />
                    </button>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-12   d-none d-lg-block harass-img infosec-cont">
              <img
                src={
                  Homedata.acf.Harassment_image.localFile.childImageSharp.fluid
                    .originalImg
                }
                alt="hero-landing"
                className="harassment-img"
              />
            </div>

            <div className="col-lg-2 desktop-col"> </div>
          </div>
        </div>
      </section>

      <section className="compliance details" id="compliance">
        <div className="container-fluid">
          <div className="row info">
            <div className="col-lg-2"></div>
            <div className="col-lg-8 col-md-12">
              <div className="title-compliance">
                {parser(Homedata.acf.Compliance_title_homepage)}
              </div>
              <div className="title-desc">
                {parser(Homedata.acf.Compliance_subtitle_description_homepage)}
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>

          <div className="row">
            <div className="col-lg-2 desktop-col"></div>
            <div className="col-lg-4  col-md-12  d-none d-lg-block justify-content-center flex-column info-img infosec-cont">
              <img
                src={
                  Homedata.acf.Compliance_image.localFile.childImageSharp.fluid
                    .originalImg
                }
                alt="hero-landing"
                className="compliance-landingsrc"
              />
            </div>

            <div className="col-lg-4 col-md-12    d-flex justify-content-center flex-column compliance-cont infosec-cont pt-4">
              <div className="row  full-icon-desc">
                <div className="col-lg-2 col-md-12 img-top">
                  <img
                    src={
                      Homedata.acf.Compliance_list_one_image.localFile
                        .childImageSharp.fluid.originalImg
                    }
                    alt="comp-icon1"
                  />
                </div>
                <div className="col-lg-10 col-md-12 icon-desc">
                  {parser(Homedata.acf.Compliance_list_one_title)}
                  {parser(Homedata.acf.Compliance_list_one_description)}
                  {/* Immersive and enriched learning experience <br />
                        with better knowledge retention */}
                </div>

                <hr className="icon-border" />
              </div>

              <div className="row  full-icon-desc">
                <div className="col-lg-2 col-md-12 img-top">
                  <img
                    src={
                      Homedata.acf.Compliance_list_second_image.localFile
                        .childImageSharp.fluid.originalImg
                    }
                    alt="comp-icon2"
                  />
                </div>
                <div className="col-lg-10 col-md-12 icon-desc">
                  {parser(Homedata.acf.Compliance_list_second_title)}
                  {parser(Homedata.acf.Compliance_list_second_description)}
                  {/* Customize the courses to meet the specific <br />
                        needs of your organization & industry */}
                </div>

                <hr className="icon-border" />
              </div>

              <div className="row  full-icon-desc">
                <div className="col-md-12 col-lg-2 img-top">
                  <img
                    src={
                      Homedata.acf.Compliance_list_third_image.localFile
                        .childImageSharp.fluid.originalImg
                    }
                    alt="comp-icon3"
                  />
                </div>
                <div className="col-lg-10 cold-md-12 icon-desc">
                  {parser(Homedata.acf.Compliance_list_third_title)}
                  {parser(Homedata.acf.Compliance_list_thrid_description)}
                </div>
              </div>

              <div className="row full-icon-desc">
                <div className="col-lg-2 col-md-3"></div>
                <div className="col-lg-10 col-md-9 infosec">
                  <Link
                    to="/financial-compliance/"
                    style={{
                      "padding-top": "15px",
                    }}
                  >
                    <button
                      type="button"
                      class="btn btn-primary btn-sm know-more"
                      id="info-know-more"
                    >
                      Know More <FaAngleDoubleRight />
                    </button>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-2 desktop-col"></div>
          </div>
        </div>
      </section>

      <section className="lms details" id="lms">
        <div className="container-fluid">
          <div className="row info">
            <div className="col-lg-1"></div>
            <div className="col-lg-10 col-md-12">
              <div className="title-lms">
                {parser(Homedata.acf.Learning_title_homepage)}
              </div>
              <div className="title-desc">
                {parser(Homedata.acf.Learning_subtitle_description_homepage)}
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>

          <div className="row ">
            <div className="col-lg-2 desktop-col"></div>
            <div className="col-lg-4 col-md-12   lms-cont infosec-cont pt-4">
              <div className="row  full-icon-desc">
                <div className="col-md-12 col-lg-3 img-top">
                  <img
                    src={
                      Homedata.acf.Learning_list_one_image.localFile
                        .childImageSharp.fluid.originalImg
                    }
                    alt="lms-icon1"
                  />
                </div>
                <div className="col-md-12 col-lg-9 icon-desc">
                  {parser(Homedata.acf.Learning_list_one_title)}

                  {parser(Homedata.acf.Learning_list_one_description)}
                </div>
                <hr className="lms-icon-border" />
              </div>

              <div className="row  full-icon-desc">
                <div className="col-md-12 col-lg-3 img-top">
                  <img
                    src={
                      Homedata.acf.Learning_list_second_image.localFile
                        .childImageSharp.fluid.originalImg
                    }
                    alt="lms-icon2"
                  />
                </div>
                <div className="col-md-12 col-lg-9 icon-desc">
                  {parser(Homedata.acf.Learning_list_second_title)}
                  {parser(Homedata.acf.Learning_list_second_description)}
                  {/* Integrate with your existing HR/Reporting
                        <br /> systems using our REST API */}
                </div>
                <hr className="lms-icon-border" />
              </div>

              <div className="row  full-icon-desc">
                <div className="col-md-12 col-lg-3 img-top">
                  <img
                    src={
                      Homedata.acf.Learning_list_third_image.localFile
                        .childImageSharp.fluid.originalImg
                    }
                    alt="lms-icon3"
                  />
                </div>
                <div className="col-md-12 col-lg-9 icon-desc">
                  {parser(Homedata.acf.Learning_list_third_title)}
                  {parser(Homedata.acf.Learning_list_thrid_description)}
                </div>
              </div>

              <div className="row full-icon-desc">
                <div className="col-md-3"></div>
                <div className="col-md-9 infosec">
                  <Link
                    to="/succeedlms"
                    style={{
                      "padding-top": "15px",
                    }}
                  >
                    <button
                      type="button"
                      class="btn btn-primary btn-sm know-more"
                      id="harass-know-more"
                    >
                      Know More <FaAngleDoubleRight />
                    </button>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-4  col-md-12 d-none d-lg-block  harass-img infosec-cont">
              <img
                src={
                  Homedata.acf.Learning_image.localFile.childImageSharp.fluid
                    .originalImg
                }
                alt="compliance-landing"
                className="lms-landingsrc"
              />
            </div>
            <div className="col-lg-2 desktop-col"></div>
          </div>
        </div>
      </section>

      <hr className="testimonials-border" />

      <section className="testimonials" id="testimonials">
        <Quote
          testimonialtitle={"Client Testimonials"}
          title={"Roja Puppala "}
          subtitle={"Learning & Development Specialist"}
          description={
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
          }
        />
      </section>

      <section className="demo" id="demo">
        <Demo />
      </section>
    </>
  )
}

export default Homedesc
