import React from "react"
import parser from "react-html-parser"
const Homepagedemo = ({ data }) => {
  return (
    <>
      <section className="homepagedemo" id="homepagedemo">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h3>Why SucceedLEARN?</h3>
            </div>
          </div>
          {/* 1st SucceedLearn */}
          <div className="row">
            <div className="col-lg-4 text-center">
              <div className="img-one">
                <img
                  src={
                    data.why_succeed1_image.localFile.childImageSharp.fluid
                      .originalImg
                  }
                  style={{ margin: "0" }}
                />
              </div>
              <div className="description firstsl">
                {parser(data.why_succeed1_title)}

                {parser(data.why_succeed1_description)}
              </div>
            </div>
            <div className="col-lg-4 text-center">
              <div className="img-one">
                <img
                  src={
                    data.why_succeed2_image.localFile.childImageSharp.fluid
                      .originalImg
                  }
                  style={{ margin: "0" }}
                />
              </div>
              <div className="description secondsl">
                {parser(data.why_succeed2_title)}

                {parser(data.why_succeed2_description)}
              </div>
            </div>
            <div className="col-lg-4 text-center">
              <div className="img-one">
                <img
                  src={
                    data.why_succeed3_image.localFile.childImageSharp.fluid
                      .originalImg
                  }
                  style={{ margin: "0" }}
                />
              </div>
              <div className="description thirdsl">
                {parser(data.why_succeed3_title)}

                {parser(data.why_succeed3_description)}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Homepagedemo
