import React from "react"
import { Link } from "gatsby"

import "../css/Demo.css"
const Demo = () => {
  return (
    <div className="container-fluid bg-grey ">
      <div className="row bg-color">
        <div className="col-lg-2 d-md-none d-lg-flex"></div>
        <div className="col-lg-4 col-md-12  order-2 order-md-12 order-lg-1 img-txt">
          <img
            src="https://res.cloudinary.com/dhrgc7mqu/image/upload/v1606203701/succeedlearn/yvqnbohplyfpnir14knt.png"
            alt="succeed-works"
          />
        </div>
        <div className="col-lg-4 col-md-12 order-1 order-lg-12 schedule-demo">
          <h5>
            See How Succeed will <br />
            work for your organization
          </h5>
          <div className="demobtn">
            <Link to="/contact#schedule">
              <button className="demo-btn">Schedule a Demo</button>
            </Link>
          </div>
        </div>
        <div className="col-lg-2 d-md-none"></div>
      </div>
    </div>
  )
}

export default Demo
